import m from 'mithril'
import stream from 'mithril/stream'
import * as R from 'ramda'
import AppEvents from './components/CMain/AppEvents'
import CT from './CT'

const forceLog = 0

let subscriptionChannel
let asyncTasks = {}
let asyncProgress = stream()
let busyChecker = false

const ASYNC_POLLING_INTERVAL = 500
const ASYNC_RESOLVE_MODE = {'POLL': 1, 'SSE': 2}
const ASYNC_MODE = ASYNC_RESOLVE_MODE.SSE

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Utils
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Debug log granted
 */
const shouldLog = () => (forceLog || CT.IS_DEBUG())

/*
 *  Updates the average progress of all async queries for display some feedback to user.
 */
const updateAsyncTaskProgress = () => {
    let average = {total: 0, sum: 0, result: 0}

    Object.keys(asyncTasks).forEach((taskId) => {
        let _task = asyncTasks[taskId]
        if (_task.started && !_task.resolved) {
            let value = _task.progress
            average.total++
            average.sum += value
        }
    })

    if (average.total > 0) average.result = Math.floor((average.sum / average.total))

    if (shouldLog()) console.log('Async average status: ', average)

    asyncProgress(average.result)
}


/*
 *  Returns a object with the parameters of a URL like string
 */
const getQueryParameters = (url) => {
    let queryParams = {}

    if (url) {
        let hashes = url.slice(url.indexOf('?') + 1).split('&')

        hashes.reduce((queryParams, hash) => {
            let pos = hash.indexOf('=')
            if (pos >= 0) queryParams[hash.substr(0, pos)] = hash.substr(pos + 1)
            return queryParams
        }, queryParams)
    }

    return queryParams
}

/*
 *   Exclusive SSE. When a completed task event arrive, the post treatment of the request about resource
 *   will be managed by this hook to fully reject or resolve the original task.
 */
const asyncCompletedMethod = (xhr) => {
    let result = {}

    if (xhr.status >= 400) throw new Error(`Code status ${xhr.status}`)

    const location = xhr.getResponseHeader('Location')
    const contentType = xhr.getResponseHeader('Content-Type')
    const isJSON = contentType ? contentType.indexOf('application/json') !== -1 : false

    if (!contentType) console.error('Undefined header Content-Type', contentType)

    try {
        if (xhr.responseText) result = isJSON ? JSON.parse(xhr.responseText) : xhr.responseText
    } catch (err) {
        console.error('Error on response extraction:', err)
    }

    let taskId = xhr['_options_']['_taskId']

    if (taskId !== '') {
        if (xhr.status === 200) {
            if (!isJSON) {
                resolveAsyncFileResult(taskId, `/api/async/response?taskid=${taskId}` /*location*/)
            } else {
                resolveTask(taskId, result)
            }
        } else {
            rejectTask(taskId, `Bad response asyncCompletedMethod. Status ${xhr.status}.`)
        }
    } else {
        console.error('Async.asyncCompletedMethod - Unknown taskId to resolve.')
    }

    return result
}


/*
 *   Exclusive polling mode. Retry status query until task is done and a location of resource is provided.
 *   Once resource is requested, original task is resolved or rejected.
 */
const pollExtractionMethod = (xhr) => {
    let result = {}

    if (xhr.status >= 400) throw new Error(`Code status ${xhr.status}`)

    const location = xhr.getResponseHeader('Location')
    const contentType = xhr.getResponseHeader('Content-Type')
    const isJSON = contentType ? contentType.indexOf('application/json') !== -1 : false

    if (!contentType) console.error('Undefined header Content-Type', contentType)

    let taskId = getQueryParameters(location)['taskid']
    if (!taskId) taskId = xhr['_options_']['_taskId']
    let task = asyncTasks[taskId]

    try {
        if (xhr.responseText) result = isJSON ? JSON.parse(xhr.responseText) : xhr.responseText
    } catch (err) {
        console.error('Error on response extraction:', err)
    }

    if (xhr.status === 200) { // OK
        ++task.counter
        let timer = task.counter < 10 ? (task.counter * ASYNC_POLLING_INTERVAL) : ASYNC_POLLING_INTERVAL * 10 // .5' 1' 1.5' 2' 2.5' 3' ... MAX 5'

        setTimeout(() => {
            if (shouldLog()) console.log(`%cRetry poll about ${taskId} after ${timer}ms`, `color: green;`)
            checkPendingAsyncQuery(taskId)
        }, timer)
    } else if (xhr.status === 201) { // CREATED
        if (location) {
            if (!isJSON) {
                // Pdf, xls and others
                resolveAsyncFileResult(task, location)
            } else {
                resolveAsyncResult(location, task)
            }
        }
    } else {
        console.error('pollExtractionMethod - STATUS', xhr.status)
    }

    // Get and notify request progress
    if (result && result.progress) {
        let progress = parseInt(result.progress)
        task.progress = task.progress < progress ? progress : task.progress
        updateAsyncTaskProgress()
    } else if (result && result.completed) {
        task.progress = 100
        updateAsyncTaskProgress()
    }

    return result
}


/*
 *   Starts and register a new asynchronous query against server.
 *   Once started it will poll server or listen server side events, based on the resolve mode set to
 *   request result when be ready.
 */
const startAsyncQuery = (task) => {
    if (!R.isEmpty(task)) {
        if (task.id !== undefined) {
            if (shouldLog()) console.log(`Task ${task.id} STARTED.`)

            if (task.id === 0) console.error('Async QUERY with ZERO IDENTIFIER!')

            //
            // README:                         *** TASK TRACKING ***
            //
            // If a taskId it's already existing, it is because a SSE arrives before trigger the asynchronous mechanism
            // (once original request response arrives,  it's known if it should be treated as async or not).
            // On these cases, the task have the minimal data, relative to its progress (updated on SSE hooks),
            // but still have not mapped proper promises or location URL to ask result (they're added on this function).
            //
            // The task may be already existing, but never can be started twice! Every async request has its own ID,
            // even if two queries are identically.
            //
            //  JDS - Jun 2020
            //     Last paragraph now isn't true. Due changes on server caching, same queries over time could be
            //     resolved as async tasks already completed. So it's needed ask about its result again.


            let alreadyExistingTask = asyncTasks[task.id]

            if (alreadyExistingTask) {

                if (shouldLog()) console.log(`%cstartAsyncQuery - alreadyExistingTask ${task.id}`, 'color: orangered;', {alreadyExistingTask})

                if (alreadyExistingTask.started) {

                    // Original code:
                    // return 0        // a taskID never should be started twice

                    // JDS, June 2020 - Server async caching enable request several times same task id
                    if (alreadyExistingTask.resolved) {
                        if (shouldLog()) console.log(`%cstartAsyncQuery - COMPLETED AND RESOLVED task ${task.id} requested again.`, 'color: orangered;')
                        // Config task to re-run asynchronous cycle (already finished task)
                        alreadyExistingTask.resolved = false
                        alreadyExistingTask.resolvedAt = ''
                        alreadyExistingTask.promise = alreadyExistingTask.promise.concat(task.promise)
                        checkPendingAsyncQuery(alreadyExistingTask)
                    } else {
                        // Just add another request to resolve when finish
                        if (shouldLog()) console.log(`%cstartAsyncQuery - STILL RUNNING task ${task.id} requested again.`, 'color: orangered;')
                        alreadyExistingTask.promise = alreadyExistingTask.promise.concat(task.promise)
                    }

                    return

                } else {

                    // Merge meta-data created by SSE events with REQUEST async meta-data

                    asyncTasks[task.id] = R.mergeRight(task, alreadyExistingTask) // add async task params (promise)

                    let _task = asyncTasks[task.id]
                    _task.started = true
                    _task.forcedPoll = false
                    _task.timeStamp = Date.now()

                    if (_task.completed) {
                        // Just initiated but already complete at server

                        if (_task.id !== undefined && _task.location !== undefined) {
                            onFinishHandler({data: {t: _task.id, l: _task.location}})
                        } else {
                            console.error('Starting existing & completed task error. Bad id/location')
                        }

                    }

                }
            } else {
                // Register it!
                asyncTasks[task.id] = task
                asyncTasks[task.id].started = true
                asyncTasks[task.id].forcedPoll = false
                asyncTasks[task.id].timeStamp = Date.now()
            }

            switch (ASYNC_MODE) {
                case ASYNC_RESOLVE_MODE.SSE:
                    if (!subscriptionChannel) {
                        subscriptionChannel = AppEvents.eventReceived.map(serverEventHandler)
                    }
                    break
                case ASYNC_RESOLVE_MODE.POLL:
                    setTimeout(() => {
                        checkPendingAsyncQuery(task)
                    }, ASYNC_POLLING_INTERVAL)
                    break
            }
        } else {
            console.error(`Tried to start a non identifiable task.`, task)
        }
    }
}

const abortAsyncQuery = (task) => {
    let id = (typeof task === 'object') ? task.id : task
    let _task = asyncTasks[id]
    if (_task) {
        if (!task.resolved) {
            rejectTask(_task, {msg: 'Aborted'})
        }
    }
}

const progressAsyncQuery = (task) => {
    let id = (typeof task === 'object') ? task.id : task
    let _task = asyncTasks[id]
    let progress = 0
    if (_task) progress = _task.progress
    return progress
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  SSE related stuff
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  Event action switcher
 */
const serverEventHandler = (event) => {
    switch (event.type) {
        case 'ProgressRequestMethodEvent':
            if (shouldLog()) {
                let taskId = event.data.ticket || event.data.t
                let progress = event.data.progress
                let task = asyncTasks[taskId]
                if (task) {
                    if (task.forcedPoll) console.log(`%cReceived PROGRESS ${progress} event on TASK ${taskId} when start POLLING.`, 'color: chocolate;')
                    if (task.completed) console.log(`%cReceived PROGRESS ${progress} event on TASK ${taskId} when was COMPLETED.`, 'color: chocolate;')
                    if (!task.started) console.log(`%cReceived PROGRESS ${progress} event on TASK ${taskId} when is !STARTED.`, 'color: chocolate;')
                } else {
                    console.warn(`Received PROGRESS ${progress} event on !STARTED task ${taskId}.`, event)
                }
            }
            onProgressHandler(event)
            break
        case 'RequestMethodCompleteEvent':
            if (shouldLog()) {
                let taskId = event.data.ticket || event.data.t
                let task = asyncTasks[taskId]
                if (task) {
                    if (task.forcedPoll) console.log(`%cReceived FINISH event on TASK ${taskId} when start POLLING.`, 'color: crimson;')
                    if (task.completed) console.log(`%cReceived FINISH event on TASK ${taskId} when was COMPLETED.`, 'color: crimson;')
                    if (!task.started) console.log(`%cReceived FINISH event on TASK ${taskId} when is !STARTED.`, 'color: crimson;')
                } else {
                    console.warn(`Received FINISH event on !STARTED task ${taskId}.`, event)
                }
            }
            onFinishHandler(event)
            break
        case 'RequestMethodCancelledEvent':
            cancelHandler(event)
            break
        default:
            defaultHandler(event)
            break
    }
}


/*
 *   Handler the 'ProgressRequestMethodEvent' event to notify request progress evolution.
 */
const onProgressHandler = (event) => {
    let id = event.data.ticket
    if (id) {
        let task = asyncTasks[id]

        if (task && !task.completed && !task.resolved) {

            if (shouldLog()) console.log(`Task ${id} PROGRESS: ${event.data.progress}% -> !COMPLETED !RESOLVED`)

            let progress = parseInt(event.data.progress)
            if (progress) {
                task.progress = task.progress < progress ? progress : task.progress
                task.timeStamp = Date.now()

                // JDS: It was never used, but was coded due can resolve a problem (without polling).
                //      It isn't used due client can receive a 404 from server (query while still not FINISH event was fired).
                //
                // if (event.data.progress >= 100) onFinishHandler({data: {t: id, l: `/api/async/response?taskid=${id}`}})

                updateAsyncTaskProgress()
            }
        } else {
            let progress = parseInt(event.data.progress)

            if (shouldLog()) console.log(`Task ${id} PROGRESS: ${event.data.progress}% -> UNDEFINED!`, task)

            if (progress) {
                // The task isn't yet registered, but quick query may be sending events right now.
                if (!asyncTasks[id]) {
                    asyncTasks[id] = {
                        started: false,
                        forcedPoll: false,
                        completed: false,
                        progress: progress,
                        counter: 0,
                        resolved: false,
                        resolvedAt: undefined,
                        timeStamp: Date.now()
                    }
                }
            }
        }
    } else {
        console.error(`Task ${id} PROGRESS: ${event.data.progress}% -> UNDEFINED ID TASK!.`)
    }
}


/*
 *   Handler the 'RequestMethodCompleteEvent' event to trigger proper action once task is completed.
 */
const onFinishHandler = (event) => {
    let id = event.data.t
    let location = event.data.l
    if (id) {
        let task = asyncTasks[id]

        if (task) {
            if (location) {
                task.location = location  // now resource location arrives here
                task.completed = true     // now can be resolved ... if was initiated
                task.progress = 100       // ...already should be at there, but for security checks.
                task.timeStamp = Date.now()

                if (!task.resolved) {
                    if (task.started) {

                        if (task.isReport) {
                            resolveAsyncFileResult(task, location)
                        } else {
                            if (forceLog || CT.IS_DEBUG()) console.log(`Task ${id} FINISHED. Location: ${task.location}`)

                            let options = {
                                method: 'GET',
                                url: task.location,
                                extract: asyncCompletedMethod,
                                _taskId: id
                            }

                            options.config = (xhr) => {
                                xhr._options_ = options
                            }

                            m.request(options).catch((err) => { rejectTask(task, err)})
                        }
                    } else {
                        if (shouldLog()) console.warn('Tried to finish a non started task.', task)
                    }
                }
            } else {
                console.error(`Task ${id} FINISH event without location.`, event)
            }
        } else {
            if (!asyncTasks[id]) asyncTasks[id] = {
                started: false,
                forcedPoll: false,
                completed: true,
                progress: 100,
                counter: 0,
                resolved: false,
                resolvedAt: undefined,
                location: location,
                timeStamp: Date.now()
            }
        }
    } else {
        console.error(`onFinishHandler - Tried to start a non identifiable task ${id}.`, event)
    }
}


/*
 *   Handler the 'RequestMethodCancelledEvent' event to trigger proper action once task is canceled.
 */
const cancelHandler = (event) => {}

/*
 *   Handler the non related event. For development purposes.
 */
const defaultHandler = (event) => {}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Poll related stuff
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/*
 *   Polling async request status
 */
const checkPendingAsyncQuery = (task) => {
    let id = (typeof task === 'object') ? task.id : task
    let _task = asyncTasks[id]

    console.log(`%cPOLL checkPendingAsyncQuery ${_task.id} @ ${_task.location}`, 'color: crimson;')

    let location = `/api/async/status?taskid=${_task.id}` // _task.location
    if (_task) {
        let options = {
            method: 'GET',
            url: location,
            extract: pollExtractionMethod, // It will call itself again if task isn't done
            _taskId: _task.id
        }

        options.config = (xhr) => {
            xhr._options_ = options
        }

        m.request(options).catch((err) => { rejectTask(_task, err)})
    }
}


/*
 *  Final resolution of original request with JSON like response.
 */
const resolveAsyncResult = (resultLocation, task) => {
    let id = (typeof task === 'object') ? task.id : task
    let _task = asyncTasks[id]

    if (_task) {

        _task.tryToGetResult = (_task.hasOwnProperty('tryToGetResult')) ? (_task.tryToGetResult + 1) : 1

        m.request({method: 'GET', url: resultLocation})
            .then((res) => {
                resolveTask(_task, res)
            })
            .catch((err) => {
                if (_task.tryToGetResult <= 2) {  // random 404 fails, but seems not resolve anything reties. Check server side.
                    setTimeout(() => {
                        resolveAsyncResult(resultLocation, task)
                    }, 300)
                } else {
                    rejectTask(_task, err)
                }
            })
    }
}


/*
 *  Final resolution of original request with static file response.
 */
const resolveAsyncFileResult = (task, location) => {
    if (!R.isEmpty(task)) {
        let id = (typeof task === 'object') ? task.id : task
        let _task = asyncTasks[id]

        let options = {
            method: 'GET',
            url: location,
            config: (xhr) => {
                xhr.responseType = 'arraybuffer' // blob
            },
            extract: (xhr) => {
                let result = {}

                let contentDisposition = xhr.getResponseHeader('Content-Disposition')

                if (!contentDisposition) {  // Preview error msg from server
                    let errorMsg = ''
                    if (xhr.responseType === 'arraybuffer') {
                        let dataView = new DataView(xhr.response)
                        let decoder = new TextDecoder('utf8')
                        let decodedString = decoder.decode(dataView)
                        errorMsg = decodedString
                        throw {isHtml: true, content: errorMsg}
                    }
                }

                let fileName = contentDisposition.slice(contentDisposition.indexOf('=') + 1)

                if (xhr.status === 200) { // OK
                    try {
                        result = {
                            contentType: xhr.getResponseHeader('Content-Type'),
                            value: xhr.response,
                            fileName: fileName
                        }
                    } catch (err) {
                        console.error('resolveAsyncFileResult response extraction:', err)
                    }
                    _task.progress = 100
                    _task.completed = true
                    updateAsyncTaskProgress()
                } else {
                    throw new Error(`Code status ${xhr.status}`)
                }
                return result
            },
            serialize: (o) => o,
            deserialize: (o) => o
        }

        m.request(options)
            .then((result) => {
                resolveTask(task, result)
            })
            .catch((err) => {
                rejectTask(task, err)
            })
    }
}


const resolveTask = (task, value) => {
    if (!R.isEmpty(task)) {
        let id = (typeof task === 'object') ? task.id : task
        let _task = asyncTasks[id]

        if (_task) {
            if (shouldLog()) console.log(`Task ${id} RESOLVED.`, value)
            //_task.promise.resolve(value)

            _task.promise.forEach((requestPromise) => {
                if(!requestPromise.completed) {
                    requestPromise.completed = true
                    requestPromise.resolve(value)
                }
            })

            removeMappedTask(_task)
            updateAsyncTaskProgress()
        } else {
            console.error('Unable to find task to resolve it.')
        }
    }
}

const rejectTask = (task, value) => {
    if (!R.isEmpty(task)) {
        let id = (typeof task === 'object') ? task.id : task
        let _task = asyncTasks[id]
        if (_task) {
            if (shouldLog()) console.error(`Task ${id} REJECTED.`)
            //_task.promise.reject(value)

            _task.promise.forEach((requestPromise) => {
                if(!requestPromise.completed) {
                    requestPromise.completed = true
                    requestPromise.reject(value)
                }
            })

            removeMappedTask(_task)
            updateAsyncTaskProgress()
        }
    }
}


const deferredDeletion = () => {
    const seconds = 60 // JDS - 26/06/2020 - changed due cached tasks ..... 5  // If the task is already done, rarely will come events of completion/progress

    let limitTimeStamp = Date.now() - (seconds * 1000) // all resolved task under this time limit should be deleted

    let toRemove = Object.keys(asyncTasks).filter((key) => {
        let task = asyncTasks[key]
        return (task.resolved && task.resolvedAt < limitTimeStamp)
    })

    toRemove.forEach((id) => {
        if (shouldLog()) console.log(`Async REQ ${id} removed.`)
        delete asyncTasks[id]
    })


    if (shouldLog()) console.log(`Async Map cleaned. Pending tasks:`, asyncTasks)

    updateAsyncTaskProgress()
}


/*
 *  Removes task of mapped request and close subscriptions if none is pending.
 */
const removeMappedTask = (task) => {
    let id = (typeof task === 'object') ? task.id : task

    let _task = asyncTasks[id]

    // Due the SSE can arrive even if a task is fully resolved, the task will might be stuck on the map.
    // To avoid this, it's done a deferred deletion.
    _task.resolved = true
    _task.resolvedAt = Date.now()

    deferredDeletion()
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const CYCLE_CHECK_TIME = 2500            // Check loop interval (ms)
const MAX_STACKED_TASK_TIME = 3000       // Max time of task without any updating (ms)

let asyncTaskChecker

const enableAsyncTasksChecker = () => {
    if (!asyncTaskChecker) asyncTaskChecker = setInterval(taskChecker, CYCLE_CHECK_TIME)

}

const disableAsyncTaskChecker = () => {
    if (asyncTaskChecker) clearInterval(asyncTaskChecker)
}


/*
 *  Due stucked tasks on loosed events, the polling mechanism will be used if any task sets stucked after MAX_STACKED_TASK_TIME ms.
 */

const taskChecker = () => {
    let rightNow = Date.now()
    let fired = 0

    if (!busyChecker) {
        busyChecker = true
        Object.keys(asyncTasks).forEach((taskId) => {
            let checkingTask = asyncTasks[taskId]
            if (checkingTask.started && !checkingTask.resolved && !checkingTask.forcedPoll) {
                let lastEventUpdate = Math.floor((rightNow - checkingTask.timeStamp))
                if (lastEventUpdate > MAX_STACKED_TASK_TIME) {
                    fired++
                    checkingTask.forcedPoll = true

                    if (shouldLog()) console.log(`%cTask ${checkingTask.id} check? FORCED! -> ${checkingTask.progress}% ${checkingTask.started ? 'STARTED' : '!STARTED'} ${checkingTask.resolved ? 'RESOLVED' : '!RESOLVED'}`, 'color: crimson;', checkingTask)

                    checkPendingAsyncQuery(checkingTask)
                }
            } else {
                // if (shouldLog()) console.log(`%cTask ${checkingTask.id} check? SKIPPED -> ${checkingTask.progress}% ${checkingTask.started ? 'STARTED' : '!STARTED'} ${checkingTask.resolved ? 'RESOLVED' : '!RESOLVED'}  ${checkingTask.forcedPoll ? 'FORCED POLL' : 'EVENT'}`, checkingTask.forcedPoll ? !checkingTask.resolved ? 'color: red;' : 'color: orange;' : 'color: green;')
            }
        })

        busyChecker = false

        if (fired > 0) console.log(`%c DETECTED STUCKED TASKs!!! Fired polling on ${fired} tasks.`, 'color: crimson;')
    } else {
        console.log(`%c SKIPPED taskChecker.`, 'color: crimson;')
    }
}

enableAsyncTasksChecker()

export default {
    asyncProgress,
    startAsyncQuery,
    abortAsyncQuery,
    progressAsyncQuery
}
