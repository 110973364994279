import m from "mithril"
import i18n from 'i18next'
import stream from 'mithril/stream'
import P from '../../../procurer/procurer_common'
import CT from '../../../src/CT'
import * as R from 'ramda'

export default (cfg) => {
    let domBtnClose

    const defaults = {
        //percentH: 30,
        message: stream(''),
        mode: stream('info'), //default mode (info). Can be: 'info' , 'error' , 'warning'
        visible: stream(false),
        title: undefined, //title to use instead of the one set in 'updateDlgStyle'
        realTitle: i18n.t("Información"),
        onCloseDlg: stream(),
        closeBtnName: i18n.t('Cerrar'),
        SMALL_CLOSE: {onclick: () => closeDlg()},
        BT_CLOSE: {
            onclick: () => closeDlg(),
            oncreate: (vnode) => {
                domBtnClose = vnode.dom
                btnCloseSetFocus()
            }
        },
        BOX: {}, //required, because it is used inside a stream.map()
        //For an external 'accept' button
        visibleAcceptBtn: stream(false),
        acceptBtnName: i18n.t('Aceptar'),
        BT_ACCEPT: {onclick: () => closeDlg({ok: true})},
        customOptions: undefined //Can contain an array of possible options that will be converted to buttons when mode is 'custom'
    }
    let c = P.mergeProperties(defaults, cfg)

    //Closes the dialog. 'param' can contain info depending on wich button was pressed)
    //If a 'onCloseDlgHook' is present, it is invoked
    const closeDlg = (param = {ok:false}) => {
        c.visible(false)
        if (c.onCloseDlgHook) c.onCloseDlgHook(param) //Hook invoked when dialog is closing
    }

    const btnCloseSetFocus = () => {
        setTimeout(() => {if (domBtnClose) {domBtnClose.focus()}}, CT.MS_DELAY_SET_FOCUS) //Timeout required to set focus properly on every browser!
    }

    c.getDialogTitle = () => c.realTitle

    const updateDlgStyle = () => {
        // Setting title style & content
        c.DLG_TITLE = {className: 'modal-win-header' + ' ' + c.mode()} //Mode is 'info', 'warning', 'error'...

        switch (c.mode()) {
            case 'error':
                c.realTitle = c.title || i18n.t("Error")
                c.visibleAcceptBtn(false)
                c.closeBtnName = i18n.t('Cerrar')
                break
            case 'warning':
                c.realTitle = c.title || i18n.t("Atención")
                c.visibleAcceptBtn(false)
                c.closeBtnName = i18n.t('Cerrar')
                break
            case 'confirm':
                c.realTitle = c.title || i18n.t("Confirmación")
                c.visibleAcceptBtn(true)
                c.closeBtnName = i18n.t('No')
                break

            case 'custom':
                c.realTitle = c.title || i18n.t("Confirmación")
                c.visibleAcceptBtn(false)
                break

            default:
                c.realTitle = c.title || i18n.t("Información")
                c.visibleAcceptBtn(false)
                c.closeBtnName = i18n.t('Cerrar')
                break
        }
        c.title = undefined // once assigned, we don't want to reuse it

        // Box style
        c.BOX = {
            className: 'modal-win-box anim-fade-in' + ' ' + c.mode(),
            style: "top: " + c.percentH + "%"
        }
    }

    //On every change of 'message' stream, innerHTML of CONTENT div must be updated
    c.message.map(msg => {
        c.CONTENT = {
            innerHTML: msg,
            style: ((msg && msg.length) > 150) ? `height: 200px; overflow-y: auto` : ''
        }
    })

    c.drawCustomOptions = () => {
        let arButtons = []
        if (c.customOptions && c.customOptions.length > 0) {
            R.forEach((option) => {
                arButtons.push(m('button.btn-small.btn-brand', {onclick: () => closeDlg(option)}, option.name))
            })(c.customOptions)
        }
        return arButtons
    }

    //-------------------------------------------------------------------------------------------
    //Set focus to 'close' button when state is set to visible
    c.visible.map((visible) => {
        if (domBtnClose && visible) btnCloseSetFocus()
        updateDlgStyle()
    })
    c.mode.map(() => updateDlgStyle())

    updateDlgStyle() //default style
    c.view = P.customizedView('CGenericDlg', c)
    return c
}

